import { FC, MouseEventHandler, memo } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Grid from "@mui/material/Grid";

import { Image } from "../../atoms";
import { colors } from "../../../shared/styles";
import { ScanButton } from "../../atoms/Button/styles";
import CloseIcon from "./closeIcon";

const Legend = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: ${props => props.color};
    padding: 0 2rem;
    text-align:center;
`;

interface ScanButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>
}

const ScanClose: FC<ScanButtonProps> = ({onClick}) => {
    const intl = useIntl();
    return (
        <Grid
            container
            direction={"column"}
            justifyContent={'center'}
            alignItems={'center'}>
            <Grid item>
                <Legend color={colors.secondary}>
                    {intl.formatMessage({id: 'scan.button_close'})}
                </Legend>
            </Grid>
            <Grid item>
                <ScanButton onClick={onClick} border="#F9941D">
                    <CloseIcon width={40} height={40} fill="#fff" />
                </ScanButton>
            </Grid>
        </Grid>
    );
}

export default memo(ScanClose);