import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { REACT_ENV } from "../utils/config";
import { useNavigate } from 'react-router-dom';

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

const Auth0ProviderWithConfig = ({
  children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  const domain = REACT_ENV.REACT_APP_AUTH0_DOMAIN;
  const clientId = REACT_ENV.REACT_APP_AUTH0_CLIENT_ID;
  const uri = REACT_ENV.REACT_APP_REDIRECT_URI;
  const audience = REACT_ENV.REACT_APP_AUDIENCE

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={uri}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};


export default Auth0ProviderWithConfig;

