import { lazy } from 'react';
import GuardProvider from './components/templates/Guard';
import Scan from './pages/Scan';
const NoMatch = lazy(() => import('./pages/NoMatch'));
const ScanResult = lazy(() => import('./pages/ScanResult'));
const ScanError = lazy(() => import('./pages/ScanError'));
const ScanViewer = lazy(() => import('./pages/ScanViewer'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const DashboardHome = lazy(() => import('./pages/Dashboard/Home'));
const Download = lazy(() => import('./pages/Download'));

const routes = [
    {
        component: <NoMatch />,
        path: '*',
    },
    {
        component: <Scan />,
        path: '/scan',
    },
    {
        component: <ScanResult />,
        path: '/scan-result/:urnQr',
    },
    {
        component: <ScanViewer />,
        path: '/viewer-load',
    },
    {
        component: <ScanError />,
        path: '/error',
    },
    {
        component: <Download />,
        path: '/download/:bucketKey/:objectKey'
    },
    {
        component: <Download />,
        path: '/download/:bucketKey/:objectKey/:filename'
    },
    {
        component: <Download />,
        path: '/downloadZip/:notificationId/:pagesSize'
    },
    {
        component: <Download />,
        path: '/downloadZip/:notificationId/:pagesSize/:filename'
    },
    {
        component: <Dashboard />,
        path: '/dashboard/*',
    },
    {
        component: <GuardProvider><DashboardHome /></GuardProvider>,
        path: '/dashboard/home',
    }
];

export default routes;
