import styled from "styled-components"

const colors = {
    'primary': '#4400FF',
    'secondary': '#FFFFFF',
    'tertiary': '#57FFE9',
    'dark': '#000000',
    // Blue
    'blue-80': '#1700e9',
    'orange': '#F9941D',
    //Gray
    'gray-70': '#68707E',
}

const backgrounds = {
    dark: '#000000',
}

export const Container = styled.div<{
    height?: string | number
}>`
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: ${(props) => props.height ? props.height : "auto"};
    justify-content: center;
`

export const Copyright = styled.div`
    display: flex;
    justify-content: center;
    max-width: 20rem;
    padding: 1rem 0;
    width: 100%;
`

export const Spinner = styled.div`
    border-left-color: #fff;
    border-radius: 50%;
    border: 4px solid #1976d2;
    height: 2rem;
    width: 2rem;

    animation: spin 1s ease infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export const Paragraph = styled.p`
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
`

export const Img = styled.img``;

export {
    colors,
    backgrounds
}