import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../Context/Auth';


interface GuardProviderProps {
    children: React.ReactNode | any
}

const GuardProvider: React.FC<GuardProviderProps> = ({
  children,
}: GuardProviderProps) => {
  const {auth} = useContext(AuthContext);

  return auth.token || localStorage.token ? children : <Navigate to="/dashboard/" />;
}

export default GuardProvider
