import { Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Layout from './components/templates/Layout';
import routes from './Routes';
import Home from "./pages/Home";
import './App.css';
import Auth0ProviderWithConfig from './Context/Auth0';
import AuthContextProvider from "./Context/Auth";

function App() {

  return (
    <BrowserRouter>
      <Auth0ProviderWithConfig>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              {routes.map(({ path, component }, i) =>
                <Route
                  key={i}
                  path={path}
                  element={
                    <Suspense fallback={<>...</>}>
                      {component}
                    </Suspense>
                  }
                />
              )}
            </Route>
          </Routes>
        </AuthContextProvider>
      </Auth0ProviderWithConfig>

    </BrowserRouter>
  );
}

export default App;
