import { FC, memo } from "react";

interface ImageProps {
    src: string,
    alt: string,
    width?: string | number,
    height?: string | number,
}

const Image: FC<ImageProps> = ({src, width, height, alt}) => {
    return (
        <>
            <img src={src} width={width} height={height} loading="lazy" alt={alt} />
        </>
    );
}

export default memo(Image);