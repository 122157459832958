import { FC, ReactNode } from 'react';
import { FaBar } from '../../../shared/components';

interface FooterBarProps {
    children: ReactNode
}

const FooterBar: FC<FooterBarProps> = ({children}) => {
    return (
        <FaBar
            position="fixed"
            color="transparent"
            sx={{ top: 'auto', bottom: '1.875rem' }}>
            {children}
        </FaBar>
    );
}

export default FooterBar;