import Button from '@mui/material/Button';
import is from 'typescript-styled-is';
import styled from 'styled-components';
import { colors } from '../../../shared/styles';

export const ScanButton = styled(Button)`
    font-size: .8rem;
    font-weight: 400;
    height: 4.87rem;
    min-width: 4.87rem;
    padding: .2rem;
    border-radius: 3rem;
    background-color: ${props => props.color ?? colors.primary};
    
    ${is('border')`
        background-color: ${colors.dark};
        border: 2px solid ${colors.orange};
    `}

    &:hover {
        background-color: ${colors.primary};
    }
`;