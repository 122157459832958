import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import QrReader from "react-qr-reader";
import Image from '../components/atoms/Image';
import FooterBar from "../components/molecules/FooterBar";
import ScanClose from "../components/molecules/ScanClose";
import { Container, QrImage } from "../shared/components";
import { backgrounds } from "../shared/styles";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { ThemeContext } from "../Context/Theme";

const Scan = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setTheme } = useContext(ThemeContext);

    function handleCancel() {
        navigate("/");
    }

    function handleScan(data:any) {
        let scannedId;
        if (data) {
            const splitedUrl = data.split('/');
            scannedId = splitedUrl.length > 1 ? splitedUrl[splitedUrl.length-1] : data;
            setTheme({
                background: '#fff',
                ellipseBg: false
            });
            navigate(`/scan-result/${scannedId}`);
        }
    }
    function handleError(err:any) {
        navigate("/error", { state: { message: intl.formatMessage({ id: 'scan_error.message' }) }});
    }

    return (
        <Container top={4} height={'100%'} style={{background: backgrounds.dark}}>
            <Grid
            container
            spacing={3}
            direction="column"
            style={{position: 'relative', margin: '5vh 0 0 0'}}>
                <QrImage>
                    <Image
                        src={`${process.env.PUBLIC_URL}/images/Subtract.svg`}
                        width={240}
                        height={240}
                        alt="scan-qr"
                    />
                </QrImage>
                <QrReader
                    delay={100}
                    style={{ width: '100%' }}
                    onError={handleError}
                    onScan={handleScan}
                    className='qr-reader'
                />
            </Grid>
            <FooterBar>
                <ScanClose onClick={handleCancel}/>
            </FooterBar>
        </Container>
    );
}

export default Scan;