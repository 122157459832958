import MTAppBar  from '@mui/material/AppBar';
import styled from "styled-components"
import { colors } from '../../../shared/styles';

const AppBar = styled(MTAppBar)`
    background: ${colors.primary};
    width: 100%;
`

export {
    AppBar
}