import { FC, memo } from 'react';
import styled from 'styled-components';
import MTCircularProgress from '@mui/material/CircularProgress';
import { colors } from '../../../shared/styles';

const CircularProgress = styled(MTCircularProgress)`
    color: ${props => props.color ?? colors.primary};
`;

interface iSpinner {
    size?: string | number,
    color?: string
}

const Spinner: FC<iSpinner> = ({
    size = 60,
    color
}) => {
    return <CircularProgress size={size} style={{'color': color}} />;
}

export default memo(Spinner);