import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Logo from '../../atoms/Logo';
import HomeIcon from '../../atoms/HomeIcon';
import { AppBar } from './styles';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from "@auth0/auth0-react";
import { REACT_ENV } from '../../../utils/config';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}));

interface IAppBar { };

const NavBar: React.FC<IAppBar> = (): JSX.Element => {
    const navigate = useNavigate();

    function handleClick() {
        navigate("/");
    }

    const { logout } = useAuth0();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <StyledToolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <Logo width={176} height={40} />
                    </Box>
                    <IconButton size="medium" aria-label="home" color="inherit" onClick={() => logout({ returnTo: REACT_ENV.REACT_APP_REDIRECT_URI })} style={{ marginRight: '0.62rem' }}>
                        <LogoutIcon sx={{ width: '1.87rem', height: '1.87rem' }} />
                    </IconButton>
                    <IconButton size="medium" aria-label="home" color="inherit" onClick={handleClick}>
                        <HomeIcon width={30} height={30} fill='#FFF' />
                    </IconButton>
                </StyledToolbar>
            </AppBar>
        </Box>
    );
}

export default NavBar;