import styled from "styled-components";
import { colors } from "../../../shared/styles";

const Label = styled.label`
    font-family:'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.color ?? colors['gray-70']};
`;

export default Label;