import { createContext, FC, ReactNode, useState } from "react"

interface IContextTheme {
    children: ReactNode
}
interface ITheme {
    background: string;
    ellipseBg: boolean;
}
export interface IAContext {
    theme: ITheme,
    setTheme: React.Dispatch<React.SetStateAction<ITheme>>
}
const defaultValue = {
    theme: {
        background: '',
        ellipseBg: false
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTheme: () => {},
};

export const ThemeContext = createContext<IAContext>(defaultValue);

export const ThemeProvider:FC<IContextTheme> = ({
    children
}) => {
    const [theme, setTheme] = useState<ITheme>({
        background: '#9ddeff',
        ellipseBg: true
    })
    return (
        <ThemeContext.Provider value={{
            theme,
            setTheme
        }}>
            {children}
        </ThemeContext.Provider>
    )
}