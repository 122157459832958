import Grid from '@mui/material/Grid';
import React from 'react';
import { getEventGA } from '../../../shared/getEventGA';
import Spinner from '../../atoms/Spinner';
import ActionButton from '../../atoms/ActionButton';
import { Label } from '../../atoms';


const dynamicallyLoadScript = function (url: string) {
    var script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
    setTimeout(() => {
        const ChatModule = document.querySelector('.support-chat-icon');
        ChatModule && ChatModule.addEventListener('click', handleOpen)
    }, 3000);
};

const handleOpen = function () {
    getEventGA("Chat Widget", "Click in icon chat", "Open Chat");
    const ContainerChat = document.querySelector('.spartez-support-chat-container');
    if (typeof ContainerChat !== null) {
        const newConversation = ContainerChat?.querySelector('.new-conversation');
        newConversation && newConversation.addEventListener('click', handleNewConversation);
        const closeChat = ContainerChat?.querySelector('.close');
        closeChat && closeChat.addEventListener('click', handleClose);
    }
}

const handleNewConversation = function () {
    getEventGA("Chat Widget", "Click leave a Message", "Leave a message");
};

const handleClose = function () {
    getEventGA("Chat Widget", "Click close icon", "Close Chat");
    const ContainerChat = document.querySelector('.spartez-support-chat-container');
    if (typeof ContainerChat !== null) {
        const newConversation = ContainerChat?.querySelector('.new-conversation');
        newConversation && newConversation.removeEventListener('click', handleNewConversation);

        const closeChat = ContainerChat?.querySelector('.close');
        closeChat && closeChat.removeEventListener('click', handleClose);
    }
}

declare const window: any;
export default class SupportChatWidget extends React.Component {
    private _isMounted: boolean;
    state = {
        loading: true,
    }
    constructor(props: any) {
        super(props);
        this._isMounted = false;

        window.spartezSupportChat = {
            portal: 14,
            cloud: {
                jiraId: '0b129644-b745-395d-8474-6f4634015dcd',
                jiraUrl: 'https://engworkers.atlassian.net',
                urls: {
                    rest: 'https://chat-api.spartez-software.com',
                    ws: 'wss://chat-ws.spartez-software.com'
                },
            },
            delay: 1000,
            container: 'spartez-support-chat-container',
            iconClass: 'ng-chat',
            chatClass: 'spartez-support-chat-container-chat',
            callback: () => {
                if (!this._isMounted) {
                    this.removeWidgetElement();
                }
                try {
                    const getElement = document.getElementById('spartez-support-chat-container') as HTMLElement;
                    document.getElementById('spartez-container')?.appendChild(getElement);
                } catch (error) {
                    console.log('error', error)
                }
                this.setState({
                    loading: false
                });
            }
        };
    }

    componentDidMount() {
        this._isMounted = true;
        dynamicallyLoadScript("https://chat-api.spartez-software.com/chat.js");
    }

    componentWillUnmount() {
        this._isMounted = false;
        const ChatModule = document.querySelector('.support-chat-icon');
        ChatModule && ChatModule.removeEventListener('click', handleOpen);
        this.removeWidgetElement();
    }

    render() {
        return (
            <Grid
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ flexDirection: 'column' }}
            >
                <Grid id="spartez-container" item sx={{ position: 'relative' }}>
                    {this.state.loading && <ActionButton onClick={() => { }}>
                        <Spinner size={40} color="#FFFFFF" />
                    </ActionButton>
                    }
                    <div id="spartez-support-chat-container" />
                </Grid>
                <Grid item sx={{ position: 'relative', alignItems: 'center', textAlign: 'center' }}>
                    <Label>Live Chat</Label>
                </Grid>
            </Grid >
        );
    }

    removeWidgetElement() {
        const elems = Array.from(document.querySelectorAll('#spartez-support-chat-container'));
        if (elems.length) {
            elems.forEach((elm) => elm.remove());
        }
    }
};
