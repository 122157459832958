import { AppBar, Box, Grid, Typography } from "@mui/material";
import styled from "styled-components"

export const WrapperApp = styled.div<any>`
    background: ${props => props.bgColor ?? '#9ddeff'} ${props => props.ellipseBg ? `url(${process.env.PUBLIC_URL}/images/Ellipse.png) no-repeat!important` : ''};
    background-position: center 24rem!important;
    
    @media (max-height: 667px) {
        background-size: 100%!important;
    }

    @media (min-height: 700px) and (max-height: 915px) {
        background-size: 100% 30rem!important;
    }

    @media (min-height: 915px) {
        background-size: 120% 100%!important;
    }
`;

export const ContainerGradient = styled<any>(Grid)``;

export const Container = styled(Box)`
    display: flex;
    position:fixed;
    top: ${props => `${props.top}rem` || 0};
    left: 0;
    width: 100%;
    height: ${props => `${props.height}` || '100vh'};
`;

export const GenieImage = styled.div`
    align-items: center; 
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (max-height: 630px) {
        bottom:4rem;
    }
`;

export const QrImage = styled(Grid)`
    position: fixed;
    top: 10rem;
    width: 100%;
    text-align: center;
    z-index: 1;
`

export const Bubble = styled<any>(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => `url(${process.env.PUBLIC_URL}/images/${props.src})`}  no-repeat top center;
    background-size: cover;
    width: 100%;
    min-width: 20rem;
    max-width: 24rem;
    height: 12rem;
`;

export const BubbleParagraph = styled<any>(Typography)`
    align-items: center;
    color: ${props => props.color};
    display: flex;
    flex-direction: row;
    font-family: Gilroy;
    font-size: 1.313rem;
    font-weight: 800;
    justify-content: space-arround;
    line-height: 1.688rem;
    margin: 0;
    overflow-wrap: break-word;
    padding: 0 0 3rem;
    text-align:center;
    width: 17rem;
`;
export const ViewerButtons = styled(Grid)`
    align-self: center;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 22rem;
`

export const FaButtons = styled(Grid)`
    align-self: center;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 20rem;
    z-index:1;
`

export const FaBar = styled(AppBar)`
    border: 0;
    box-shadow: none;
`;
