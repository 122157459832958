import { FC, memo } from "react"

interface IconProps {
    width: number;
    height: number;
    fill: string;
}
const ScanIcon: FC<IconProps> = ({ width, height, fill }) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={`0 0 20 20`}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 0h4.333v1H4a3 3 0 0 0-3 3v3.917H0V4a4 4 0 0 1 4-4Zm7.667 0v1H16a3 3 0 0 1 3 3v3.917h1V4a4 4 0 0 0-4-4h-4.333ZM16 19h-4.333v1H16a4 4 0 0 0 4-4v-4.75h-1V16a3 3 0 0 1-3 3ZM1 16v-4.75H0V16a4 4 0 0 0 4 4h4.333v-1H4a3 3 0 0 1-3-3ZM4.5 2.5h4.667v6.666H2.5V4.5a2 2 0 0 1 2-2Zm-2 13v-4.667h6.667V17.5H4.5a2 2 0 0 1-2-2Zm13 2h-4.667v-6.667H17.5V15.5a2 2 0 0 1-2 2Zm-4.667-8.334H17.5V4.5a2 2 0 0 0-2-2h-4.667v6.666Z"
            fill="#fff"
        />
    </svg>
)

export default memo(ScanIcon);
