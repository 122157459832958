import MTBox from '@mui/material/Box';
import MTypography from '@mui/material/Typography';
import MTLinearProgress from '@mui/material/LinearProgress';
import styled from "styled-components";
import { colors } from "../../../shared/styles";

export const Container = styled(MTBox)`
    display: flex;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${colors.primary}
`;

export const BoxWrapper = styled(MTBox)`
    display: flex;
    width: 100%; 
    flex-direction: column; 
    justify-content: center;
    text-align: center;
`;

export const Box = styled(MTBox)`
    width: 80%;
    margin: 1rem auto;
`;

export const Image = styled.img`
    align-self: center;
`;

export const Typography = styled(MTypography)`
    color: ${colors.secondary}
`;

export const LinearProgress = styled(MTLinearProgress)`
    background: ${colors["blue-80"]};
    span {
        background-color: ${colors.tertiary};
    }
`;