import { FC, useState, useEffect } from "react";
import packageInfo from '../../../../package.json';
import {Container, Image, Typography, LinearProgress, BoxWrapper, Box} from './styles';

interface ISplashProps {
    setSplash: any
}

const version = packageInfo.version
const velocity = 40;

const SplashScreen: FC<ISplashProps> = ({setSplash}) => {
    const [progress, setProgress] = useState(velocity);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress(
                (prevProgress) => (
                    prevProgress >= 100 ?
                    velocity :
                    prevProgress + velocity
                )
            );
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress >= 100) {
            setSplash(false);
        }
    }, [progress, setSplash]);

    return (
        <Container>
            <BoxWrapper>
                <Image src={`${process.env.PUBLIC_URL}/images/genie-splash_nv.gif`} alt="Genie Splash" loading="lazy" width="240" />
                <Box>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Typography variant="h6">v{version}</Typography>
            </BoxWrapper>
        </Container>
    );
}

export default SplashScreen;