import { FC, MouseEventHandler } from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import Label from "../Label";

interface ActionButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
    children?: React.ReactNode,
    label?: string
}

const Button = styled.button`
    background-color: #4400FF;
    border-radius: 3rem;
    border: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    height: 4.87rem;
    min-width: 3rem;
    padding: 0.5rem 0.2rem 0.2rem;
    width: 4.87rem;
`

const ActionButton: FC<ActionButtonProps> = ({
    onClick,
    children,
    label
}) => {
    return (
        <Grid
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}>
            <Grid item>
                <Button onClick={onClick}>
                    {children}
                </Button>
                <Grid item sx={{ position: 'relative', alignItems: 'center', textAlign: 'center' }}>
                    <Label>{label}</Label>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ActionButton;