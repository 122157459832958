import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import { Bubble, BubbleParagraph, ContainerGradient, FaButtons, GenieImage } from '../shared/components';
import Image from '../components/atoms/Image';
import { useIntl } from 'react-intl';
import ChatLive from '../components/molecules/ChatLive';
import ActionButton from '../components/atoms/ActionButton';
import ScanIcon from '../components/atoms/ScanIcon';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../Context/Theme';

const Home = (): JSX.Element => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setTheme } = useContext(ThemeContext);

    function handleClick() {
        navigate('/scan');
    }

    useEffect(() => {
        let isMount = true;
        if (isMount)
            setTheme({
                background: '#9ddeff',
                ellipseBg: true,
            })
        return () => {
            isMount = false
        }
    }, []);

    return (
        <ContainerGradient
            className='HomePage'
            container
            direction="column"
            mx={{ justifyContent: 'center', alignItems: 'center' }}
            style={{ margin: '0', overflowX: 'hidden' }}>
            <Grid item style={{ maxHeight: '70vh' }}>
                <Grid
                    container
                    direction="row"
                    style={{ justifyContent: 'center', maxHeight: '60vh' }}
                >
                    <Bubble
                        src='bubble.png'>
                        <BubbleParagraph
                            color='#4400FF'>
                            {intl.formatMessage({ id: 'home.text_bubble' })}
                        </BubbleParagraph>
                        <Image
                            src={`${process.env.PUBLIC_URL}/images/arrow-p-bubble.png`}
                            alt="arrow-bubble"
                        />
                    </Bubble>
                    <GenieImage>
                        <Image
                            src={`${process.env.PUBLIC_URL}/images/genie-map.png`}
                            alt="home-image"
                        />
                    </GenieImage>
                </Grid>
            </Grid>
            <FaButtons
                container
                item>
                <ActionButton
                    onClick={handleClick}
                    label={intl.formatMessage({ id: 'home.button_scan' })}
                >
                    <ScanIcon width={40} height={40} fill="#FFF" />
                </ActionButton>
                <ChatLive />
            </FaButtons>
        </ContainerGradient>
    );
}

export default Home;