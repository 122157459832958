import { FC, memo } from "react";

interface LogoProps {
    width: 176,
    height: 40
}

const Logo: FC<LogoProps> = ({
    width,
    height
}) => {
    return (
        <img 
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            width={width}
            height={height}
            loading="lazy"
            alt="Logo"
        />
    );
}

export default memo(Logo);