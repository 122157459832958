import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { FC, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../Context/Theme';
import { WrapperApp } from '../../shared/components';
import { IState } from '../../store';
import Alert from '../atoms/Alert';
import NavBar from '../molecules/NavBar';
import SplashScreen from '../molecules/SplashScreen';

interface ILayoutProps {}

const Layout: FC<ILayoutProps> = ():JSX.Element => {
    const navigate = useNavigate();
    const [splash, setSplash] = useState(true);
    const { theme } = useContext(ThemeContext);
    const {hash} = window.location;

    const isServiceWorkerInitialized = useSelector(
        (state: IState) => state.serviceWorkerInitialized,
    );
    const isServiceWorkerUpdated = useSelector(
        (state: IState) => state.serviceWorkerUpdated,
    );
    const serviceWorkerRegistration = useSelector(
        (state: IState) => state.serviceWorkerRegistration,
    );
    
    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        
            registrationWaiting.addEventListener('statechange', (e:any) => {
                if (e.target.state === 'activated') {
                window.location.reload();
                }
            });
        }
    };

    useEffect(() => {
        hash && navigate(hash.replace('#/', '/'))
    })

    return (
        <>
            <div className="App-alert">
                {isServiceWorkerInitialized && (
                <Alert
                    type={'info'}
                    label="Service Worker is initialized for the first time"
                />
                )}
                {isServiceWorkerUpdated && (
                <Alert
                    type={'success'}
                    label="New version available."
                    buttonText="Update"
                    onClick={updateServiceWorker}
                />
                )}
            </div>
            <CssBaseline />
            <NavBar />
            <Container
                style={{
                    overflowY: 'auto',
                    padding: '0',
                    maxWidth: '100%'
                }}
                >
                <WrapperApp
                    bgColor={theme.background ?? '#fff'}
                    ellipseBg={theme.ellipseBg}>
                    {splash ? <SplashScreen setSplash={setSplash}/> : <Outlet />}
                </WrapperApp>
            </Container>
        </>
    );
}

export default Layout;