import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { English } from './languages';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from './Context/Theme';

import './index.css';
import { SW_INIT, SW_UPDATE } from './swtypes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import configureStore from './store';
import flatten from 'flat';
import reportWebVitals from './reportWebVitals';

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider locale={navigator.language} messages={flatten(English)}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: registration =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
