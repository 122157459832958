import { FC, memo } from "react";

interface IconProps {
  width: number;
  height: number;
  fill: string
}
const HomeIcon:FC<IconProps> = ({width, height, fill}, props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={width}
    height={height}
    fill={fill}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="m256 2.938-256 256v48.427h62.061v201.697h155.152V384.941h77.576v124.121H449.94V307.365H512v-48.427l-256-256zM403.394 260.82v201.697h-62.061V338.396H170.667v124.121h-62.061V260.82H63.943L256 68.762 448.057 260.82h-44.663z" />
  </svg>
)

export default memo(HomeIcon);
